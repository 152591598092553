import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import { AppProps as Props } from "next/app";
import Head from "next/head";
import getConfig from "next/config";
import { useState } from "react";
import { QueryClient, QueryClientProvider, Hydrate } from "react-query";

import { Service } from "../utils/config";
import "../styles/index.css";

// Initialize Sentry to support error tracking
if (Service.Sentry.DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;

  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename?.replace(distDir, "app:///_next");
          return frame;
        }
      })
    ],
    dsn: Service.Sentry.DSN
  });
}

const App = ({ Component, pageProps, err }: Props & { err: Error }) => {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <>
      <Head>
        <script
          defer
          data-domain="clubofcode.io"
          src="https://plausible.io/js/plausible.js"
        />
      </Head>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          {/* Related to error tracking.
              Workaround for https://github.com/vercel/next.js/issues/8592 */}
          <Component {...pageProps} err={err} />
        </Hydrate>
      </QueryClientProvider>
    </>
  );
};

export default App;
