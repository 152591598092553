export const Service = Object.freeze({
  /**
   * We use Sentry to track errors.
   * @website https://sentry.io
   */
  Sentry: { DSN: process.env.NEXT_PUBLIC_ANALYTICS_SENTRY_DSN }
});

export const Config = Object.freeze({
  /**
   * The server/api of the web app.
   * @package `server`
   */
  Api: {
    ORIGIN: process.env.NEXT_PUBLIC_API_ORIGIN || "https://localhost:3001"
  },
  /**
   * The Single Page Application (SPA) built with React.
   * @package `client`
   * @website https://app.clubofcode.io/
   */
  App: {
    ORIGIN: process.env.NEXT_PUBLIC_APP_ORIGIN || "https://localhost:3000"
  },
  /**
   * The public-facing Next.js app (this app).
   * @package `client-public`
   * @website https://clubofcode.io/
   */
  This: {
    ORIGIN: process.env.NEXT_PUBLIC_THIS_ORIGIN || "https://localhost:3002"
  }
});
